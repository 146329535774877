@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nanum+Gothic+Coding&display=swap");

:root {
  --primary: #f37299;
  --background: #f0f1f3;
  --text: #1e1e1e;
  --headings: #283832;
  --descriptions: #6b6d6b;
  --divider: #cacaca;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-optical-sizing: auto;
  background-color: var(--background);
  line-height: 1.4;
}

html {
  scroll-behavior: smooth;
}

/* Hero */

.offscreen {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(1px 1px 1px 1px);
  clip-path: inset(1px, 1px, 1px, 1px);
}

.skip-to-content.offscreen:focus {
  position: relative;
  display: inline-block;
  height: auto;
  width: auto;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.625em 1.25em;
  background-color: var(--text);
  color: var(--background);
  overflow: visible;
  clip-path: none;
  text-decoration: none;
}

#home {
  border: none;
  border-top: 1px solid var(--background);
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 2rem auto;
}

.logo p {
  font-family: "Fira Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: var(--headings);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.logo a {
  text-decoration: none;
}

.logo p:hover {
  color: var(--primary);
}

.links {
  display: none;
  margin: 1rem;
}

.links ul {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.links ul li {
  text-transform: capitalize;
  cursor: pointer;
}

.links ul li a {
  font-weight: 500;
  color: var(--headings);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.links ul li a:hover {
  color: var(--primary);
}

.hamburger {
  display: flex;
  width: 48px;
  height: 48px;
  position: fixed;
  top: 1rem;
  right: 1rem;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  z-index: 200;
}

.hamburger::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--headings);
  transform: translateY(-9px);
  box-shadow: 0 9px var(--headings);
  transition: 0.3s;
}

.hamburger::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--headings);
  transform: translateY(9px);
  transition: 0.2s;
}

.hamburger.active {
  transition: 0.2s;
}

.hamburger.active::before {
  transform: translateY(0px) rotate(45deg);
  box-shadow: 0 0px var(--descriptions);
}

.hamburger.active::after {
  width: 30px;
  transform: translateY(0px) rotate(-45deg);
  box-shadow: 0 0px var(--descriptions);
}

/* Off-canvas menu */
.offcanvas-menu {
  position: fixed;
  top: 0;
  right: -270px; /* Hide off-screen initially */
  width: 250px;
  height: 100%;
  border-radius: 25px;
  background-color: var(--background);
  padding: 20px;
  transition: right 0.3s ease-in-out;
  z-index: 100;
  box-shadow: -8px 0 15px rgba(0, 0, 0, 0.2);
}

.offcanvas-menu.open {
  right: 0; /* Slide in the menu when open */
}

.offcanvas-menu ul {
  list-style: none;
  padding: 0;
  margin: 5rem 1rem;
}

.offcanvas-menu li {
  margin: 2rem 0;
}

.offcanvas-menu a {
  color: var(--text);
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  transition: color 0.3s;
}

.offcanvas-menu a:hover {
  color: var(--primary);
}

.main {
  height: 65vh;
  display: flex;
  margin-bottom: 5rem;
}

.main-block {
  width: 90%;
  margin: 0 auto;
}

.auto-type-block {
  position: relative;
  top: 15vh;
}

.auto-type {
  font-family: "Fira Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.5rem;
  min-height: 4rem;
  color: var(--headings);
}

.typed-cursor {
  display: none;
  font-size: 1.25rem;
  color: var(--headings);
}

.intro-block {
  position: relative;
  top: 20vh;
}

.whoiam {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: var(--text);
  font-size: 1.5rem;
}

.whoiam-highlight {
  font-family: "Nanum Gothic Coding", monospace;
  font-size: 1.25rem;
  color: var(--background);
  font-weight: 500;
  background-color: var(--headings);
  padding: 3px 10px;
  border-radius: 5px;
}

/* About */

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 2rem 0;
  position: relative;
  margin: 2rem 0 5rem 0;
}

.about-block {
  width: 90%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-direction: column;
}

.about-left,
.about-right {
  flex: 1;
}

.about-left h3 {
  font-size: 2rem;
  color: var(--headings);
  padding: 1.5rem 0 1rem 0;
}

.about-right p {
  font-size: 1.25rem;
  font-weight: 300;
  text-indent: 40px;
  padding: 1rem 0;
  color: var(--text);
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary);
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 1;
}

.slider::before,
.slider::after {
  content: "";
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  z-index: 0;
}

.slider::before {
  left: 0;
  background: linear-gradient(to right, var(--primary), transparent);
}

.slider::after {
  right: 0;
  background: linear-gradient(to left, var(--primary), transparent);
}

.skill-list {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  padding: 1rem;
  margin: 0;
  gap: 4rem;
  white-space: nowrap;
  animation: scroll-left 25s linear infinite;
}

.skill-list li {
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--text);
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-50%); /* Move to the left */
  }
}

/* PROJECTS */

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  display: flex;
  flex: start;
  margin: 0;
  width: 90%;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary);
}

.project-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 6rem;
  height: auto;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--text);
}

.details .item {
  flex: 1 1 100%;
  margin: 0 0 2rem 0;
}

.thumbnail {
  margin: 0.5rem 0;
  height: 350px;
  width: 100%;
  background: rgb(232, 232, 232);
  background: linear-gradient(
    180deg,
    rgba(232, 232, 232, 1) 0%,
    rgba(202, 202, 202, 1) 100%
  );
  border-radius: 20px;
  padding: 2rem;
}

.thumbnail img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.thumbnail img:hover {
  transform: scale(1.05);
}

.heading h3 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: capitalize;
  color: var(--headings);
  padding: 1.5rem 0 1rem 0;
}

.description p {
  font-size: 1.25rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
  color: var(--descriptions);
}

/* FOOTER */
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: auto;
  width: 100%;
}

.footer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.contact {
  display: block;
  width: 100%;
  padding: 0 0 3rem 0;
  border-bottom: 1px solid var(--divider);
}

.contact p {
  font-size: 1.25rem;
  color: var(--text);
  font-weight: 400;
}

.contact h4 {
  font-weight: 800;
  color: var(--headings);
  font-size: 1.75rem;
  padding: 1.5rem 0 2rem 0;
}

.contact-btn {
  color: var(--background);
  font-weight: 800;
  text-transform: capitalize;
  font-size: 1rem;
  padding: 1rem 3rem;
  border: none;
  border-radius: 30px;
  background-color: var(--headings);
  cursor: pointer;
  margin: 0.5rem 0;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: color 0.2s ease-in-out;
}

.contact-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    149deg,
    rgba(244, 120, 148, 1) 0%,
    rgba(248, 130, 136, 1) 50%,
    rgba(255, 148, 114, 1) 100%
  );
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.contact-btn:hover::before {
  opacity: 1;
}

.socials {
  display: block;
  width: 100%;
}

.socials ul {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.socials ul li {
  margin: 2rem 0;
}

.socials ul li a {
  color: var(--text);
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;
}

.socials ul li a:hover {
  color: var(--primary);
}

@media (min-width: 768px) {
  .hamburger {
    display: none;
  }

  .links {
    display: flex;
  }

  .nav-bar {
    width: 90%;
  }

  .logo p,
  .link {
    margin: 0;
  }

  .contact h4 {
    font-size: 2rem;
  }
}

@media (min-width: 1080px) {
  .logo p,
  .links ul li a {
    font-size: 1.25rem;
  }

  .main {
    height: 90vh;
    margin-bottom: 0;
  }

  .main-block,
  .about-block,
  .project-block,
  .footer-block,
  .nav-bar {
    width: 65%;
  }

  .auto-type {
    font-size: 2.5rem;
  }

  .whoiam {
    font-size: 1.5rem;
  }

  .whoiam-highlight {
    font-size: 1.25rem;
  }

  .about-left .title {
    font-size: 2.25rem;
    width: 100%;
  }

  .intro p {
    font-size: 1.25rem;
  }

  .details .item {
    max-width: calc(50% - 1rem);
  }

  .heading h3 {
    font-size: 1.5rem;
  }

  .description p {
    font-size: 1.25rem;
  }

  .contact p {
    font-size: 1.25rem;
  }

  .contact h4 {
    font-size: 2.25rem;
  }

  .contact-btn {
    font-size: 1rem;
  }

  .footer ul li a {
    font-size: 1.25rem;
  }
}

@media (min-width: 1280px) {
  .auto-type {
    font-size: 4.5rem;
    min-height: 8.5rem;
    padding-bottom: 2rem;
  }

  .whoiam {
    font-size: 2.25rem;
  }

  .whoiam-highlight {
    font-size: 2rem;
  }

  .contact h4 {
    font-size: 2.5rem;
  }

  .description p {
    font-size: 1.5rem;
  }
}
